<template>
  <main>
    <div class="card shadow-none mt-2">
      <div class="card-header">Finnish Risk (FINDRISC)</div>
      <div class="card-body">
        <div class="row mt-2">
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder">Género</label>
            <select
              class="form-control"
              v-model="dataRisk.genero"
              :class="{
                'is-invalid':
                  $v.dataRisk.genero.$error && $v.dataRisk.genero.$dirty,
              }"
              readonly
            >
              <option value="F">F</option>
              <option value="M">M</option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder">Edad</label>
            <input
              type="number"
              class="form-control"
              v-model="edad_u"
              readonly
            />
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder">IMC</label>
            <input
              type="number"
              class="form-control"
              v-model="indice_masa_corporal"
              :class="{
                'is-invalid':
                  $v.indice_masa_corporal.$error &&
                  $v.indice_masa_corporal.$dirty,
              }"
              readonly
            />
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder"
              >Usa medicamentos para la presión</label
            >
            <select
              class="form-control"
              v-model="dataRisk.medicamentos_presion"
              :class="{
                'is-invalid':
                  $v.dataRisk.medicamentos_presion.$error &&
                  $v.dataRisk.medicamentos_presion.$dirty,
              }"
            >
              <option value="y">SI</option>
              <option value="n">NO</option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder"
              >Antecedentes personales de diabetes</label
            >
            <select
              class="form-control"
              v-model="dataRisk.historial_glucosa"
              :class="{
                'is-invalid':
                  $v.dataRisk.historial_glucosa.$error &&
                  $v.dataRisk.historial_glucosa.$dirty,
              }"
            >
              <option value="y">SI</option>
              <option value="n">NO</option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder"
              >Actividad fisica en horas a la semana</label
            >
            <input
              type="number"
              class="form-control"
              v-model="dataRisk.actividad_fisica"
              :class="{
                'is-invalid':
                  $v.dataRisk.actividad_fisica.$error &&
                  $v.dataRisk.actividad_fisica.$dirty,
              }"
            />
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder">Consumo diario de frutas</label>
            <select
              class="form-control"
              v-model="dataRisk.consumo_diario_frutas"
              :class="{
                'is-invalid':
                  $v.dataRisk.consumo_diario_frutas.$error &&
                  $v.dataRisk.consumo_diario_frutas.$dirty,
              }"
            >
              <option value="y">SI</option>
              <option value="n">NO</option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder"
              >Antecedentes familiares de diabetes</label
            >
            <select
              class="form-control"
              v-model="dataRisk.historial_diabetes"
              :class="{
                'is-invalid':
                  $v.dataRisk.historial_diabetes.$error &&
                  $v.dataRisk.historial_diabetes.$dirty,
              }"
            >
              <option value="n">No</option>
              <option value="1g">1 Grado</option>
              <option value="2g">2 Grado</option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="font-weight-bolder">Perimetro abdominal</label>
            <input
              type="number"
              step="any"
              class="form-control"
              v-model="dataRisk.perimetro_abdominal"
              :class="{
                'is-invalid':
                  $v.dataRisk.perimetro_abdominal.$error &&
                  $v.dataRisk.perimetro_abdominal.$dirty,
              }"
            />
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <div class="list-group mt-2 col-lg-8 col-md-12 col-sm-12">
          <div class="list-group-item">
            <span
              class="font-weight-bolder badge badge-light badge-marketing mx-2"
              >Puntaje : {{ this.puntaje }}</span
            >
            <span :class="class_risk" class="font-weight-bolder"
              >Riesgo: {{ this.dataRisk.riesgo }}</span
            >
          </div>
          <div
            class="alert alert-danger mt-2"
            v-if="$v.puntaje.$error && $v.puntaje.$dirty"
          >
            Debe calcular el puntaje
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success mx-2 btn-sm" @click="calcular()">
            <i class="fa fa-calculator fa-fw"></i>Calcular Puntaje
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import examenFisicoService from "../../../../services/examenFisicoService";
import findriscService from "../../../../services/findriscService";
import { findrisc } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "sexo", "fecha_nacimiento"],
  data() {
    return {
      dataRisk: {
        genero: "",
        imc: "",
        medicamentos_presion: "",
        historial_glucosa: "",
        actividad_fisica: "",
        consumo_diario_frutas: "",
        historial_diabetes: "",
        riesgo: "",
        perimetro_abdominal: "",
        peso: "",
        talla: "",
        id_findrisc: "",
      },
      class_risk: "",
      puntaje: "",
    };
  },
  validations() {
    return {
      dataRisk: {
        genero: { required },
        medicamentos_presion: { required },
        historial_glucosa: { required },
        actividad_fisica: { required },
        consumo_diario_frutas: { required },
        historial_diabetes: { required },
        perimetro_abdominal: { required },
        peso: { required },
      },

      indice_masa_corporal: { required },
      puntaje: { required },
    };
  },
  methods: {
    async calcular() {
      try {
        this.$v.dataRisk.$touch();
        if (this.$v.dataRisk.$invalid) return;

        const gender = this.genero === "F" ? "female" : "male";

        const riesgo = findrisc(
          gender,
          this.edad_u,
          this.dataRisk.indice_masa_corporal,
          this.dataRisk.perimetro_abdominal,
          this.dataRisk.medicamentos_presion,
          this.dataRisk.historial_glucosa,
          this.dataRisk.actividad_fisica,
          this.dataRisk.consumo_diario_frutas,
          this.dataRisk.historial_diabetes
        );

        this.dataRisk.riesgo = riesgo["risk"];
        this.puntaje = riesgo["points"];
        this.class_risk = riesgo["class"];
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async save() {
      try {
        this.$v.puntaje.$touch();
        if (this.$v.puntaje.$invalid) return;

        if (this.puntaje === "" || this.puntaje == null) {
          Swal.fire(
            "Error",
            "Debe calcular el riesgo antes de guardar",
            "error"
          );
          return;
        }

        let findrisc_h = {
          id_historia: this.idHistoria,
          genero: this.dataRisk.genero,
          edad: this.edad_u,
          imc: this.indice_masa_corporal,
          perimetro_abdominal: this.dataRisk.perimetro_abdominal,
          medicamentos_presion: this.dataRisk.medicamentos_presion,
          historial_glucosa: this.dataRisk.historial_glucosa,
          actividad_fisica: this.dataRisk.actividad_fisica,
          consumo_diario_frutas: this.dataRisk.consumo_diario_frutas,
          historial_diabetes: this.dataRisk.historial_diabetes,
          riesgo: this.dataRisk.riesgo,
          puntaje: this.puntaje,
        };

        this.LoaderSpinnerShow();

        if (this.dataRisk.id_findrisc !== "") {
          findrisc_h["id"] = this.dataRisk.id_findrisc;
          await findriscService.update(findrisc_h);
        } else {
          await findriscService.store(findrisc_h);
          const response = await findriscService.showByHc(this.idHistoria);
          this.dataRisk.id_findrisc = response.data.id;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
  },
  async created() {
    this.dataRisk.genero = this.sexo;
    //this.findrisc.edad=this.edad;
    if (this.finalizada === "N") {
      const response = await examenFisicoService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.dataRisk.talla = response.data.talla;
        this.dataRisk.peso = response.data.peso;
        this.dataRisk.perimetro_abdominal = Number(
          response.data.perimetro_abdominal
        );
      }

      const response_findrisc = await findriscService.showByHc(this.idHistoria);
      if (!isEmpty(response_findrisc.data)) {
        //this.edad_u=response_findrisc.data.edad;
        this.dataRisk.medicamentos_presion =
          response_findrisc.data.medicamentos_presion;
        this.dataRisk.historial_glucosa =
          response_findrisc.data.historial_glucosa;
        this.dataRisk.actividad_fisica =
          response_findrisc.data.actividad_fisica;
        this.dataRisk.consumo_diario_frutas =
          response_findrisc.data.consumo_diario_frutas;
        this.dataRisk.historial_diabetes =
          response_findrisc.data.historial_diabetes;
        this.dataRisk.perimetro_abdominal =
          response_findrisc.data.perimetro_abdominal;
        this.puntaje = response_findrisc.data.puntaje;
        this.dataRisk.riesgo = response_findrisc.data.riesgo;
        this.dataRisk.id_findrisc = response_findrisc.data.id;
      }
    }
  },
  watch: {
    finalizada: async function (value) {
      if (value === "N") {
        const response = await examenFisicoService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.dataRisk.talla = response.data.talla;
          this.dataRisk.peso = response.data.peso;
          this.dataRisk.perimetro_abdominal = Number(
            response.data.perimetro_abdominal
          );
        }
      }
    },
  },
  computed: {
    indice_masa_corporal() {
      if (this.dataRisk.peso !== "" && this.dataRisk.talla !== "") {
        let imc =
          Number(this.dataRisk.peso) /
          Math.pow(Number(this.dataRisk.talla / 100), 2);
        return imc.toFixed(2);
      }

      return "";
    },
    edad_u: function () {
      if (isEmpty(this.fecha_nacimiento)) return 0;
      return dayjs().diff(this.fecha_nacimiento, "year");
    },
  },
};
</script>
