<template>
  <div class="card shadow-none">
    <div class="card-header">Framingham</div>
    <div class="card-body">
      <div class="row mt-2">
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">Género</label>
          <select class="form-control" v-model="genero">
            <option value="F">F</option>
            <option value="M">M</option>
          </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">Edad</label>
          <input type="number" class="form-control" v-model="edad_u" />
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">TA. Sistólica</label>
          <input
            type="number"
            class="form-control"
            v-model="ta_sistolica"
            :class="{
              'is-invalid': $v.ta_sistolica.$error && $v.ta_sistolica.$dirty,
            }"
          />
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">Col Total</label>
          <input
            type="number"
            class="form-control"
            v-model="col_total"
            :class="{
              'is-invalid': $v.col_total.$error && $v.col_total.$dirty,
            }"
          />
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">Col HDL</label>
          <input
            type="number"
            class="form-control"
            v-model="col_hdl"
            :class="{
              'is-invalid': $v.col_hdl.$error && $v.col_hdl.$dirty,
            }"
          />
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder"
            >Tratamiento Hipertensión</label
          >
          <select
            class="form-control"
            v-model="tratamiento_hipertension"
            :class="{
              'is-invalid':
                $v.tratamiento_hipertension.$error &&
                $v.tratamiento_hipertension.$dirty,
            }"
          >
            <option value="y">SI</option>
            <option value="n">NO</option>
          </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder">Fuma Cigarrillos</label>
          <select
            class="form-control"
            v-model="fuma_cigarrillos"
            :class="{
              'is-invalid':
                $v.fuma_cigarrillos.$error && $v.fuma_cigarrillos.$dirty,
            }"
          >
            <option value="y">SI</option>
            <option value="n">NO</option>
          </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
          <label class="form-label font-weight-bolder"
            >Presencia Diabetes</label
          >
          <select
            class="form-control"
            v-model="presencia_diabetes"
            :class="{
              'is-invalid':
                $v.presencia_diabetes.$error && $v.presencia_diabetes.$dirty,
            }"
          >
            <option value="y">SI</option>
            <option value="n">NO</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6 font-weight-bolder">
          porcentaje : {{ this.porcentaje }}
        </div>
        <div class="col-6 font-weight-bolder">Riesgo : {{ this.riesgo }}</div>
      </div>
      <div class="row mt-2">
        <button class="btn btn-secondary mx-2 btn-sm" @click="calcular()">
          <i class="fa fa-calculator fa-fw"></i> Calcular
        </button>

        <button class="btn btn-success btn-sm" @click="guardar()">
          <i class="fa fa-save fa-fw"></i> Guardar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import fragmingamService from "../../../../services/fragmingamService";
import { framingham_risk_score } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import dayjs from "dayjs";
import examenFisicoService from "../../../../services/examenFisicoService";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  props: ["idHistoria", "finalizada", "idUsuario", "sexo", "fecha_nacimiento"],
  data() {
    return {
      genero: "",
      col_total: "",
      col_hdl: "",
      presencia_diabetes: "",
      ta_sistolica: "",
      tratamiento_hipertension: "",
      fuma_cigarrillos: "",
      porcentaje: "",
      riesgo: "",
      id_fragmingam: "",
    };
  },
  validations() {
    return {
      genero: { required },
      col_total: { required },
      col_hdl: { required },
      presencia_diabetes: { required },
      ta_sistolica: { required },
      tratamiento_hipertension: { required },
      fuma_cigarrillos: { required },
    };
  },
  methods: {
    async calcular() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        const gender = this.genero === "F" ? "female" : "male";

        const riesgo = framingham_risk_score(
          gender,
          this.edad_u,
          this.ta_sistolica,
          this.col_total,
          this.col_hdl,
          this.tratamiento_hipertension,
          this.fuma_cigarrillos,
          this.presencia_diabetes
        );

        console.log(riesgo);
        this.riesgo = riesgo["classification"];
        this.porcentaje = riesgo["risk"];
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async guardar() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        if (this.porcentaje === "" || this.porcentaje == null) {
          Swal.fire(
            "Error",
            "Debe calcular el riesgo antes de guardar",
            "error"
          );
          return;
        }

        let findrisc_h = {
          id_historia: this.idHistoria,
          genero: this.genero,
          edad: this.edad_u,
          ta_sistolica: this.ta_sistolica,
          col_total: this.col_total,
          col_hdl: this.col_hdl,
          presencia_diabetes: this.presencia_diabetes,
          tratamiento_hipertension: this.tratamiento_hipertension,
          fuma_cigarrillos: this.fuma_cigarrillos,
          riesgo: this.riesgo,
          porcentaje: this.porcentaje,
        };

        this.LoaderSpinnerShow();

        if (this.id_fragmingam !== "") {
          findrisc_h["id"] = this.id_fragmingam;
          await fragmingamService.update(findrisc_h);
        } else {
          await fragmingamService.store(findrisc_h);
          const response = await fragmingamService.showByHc(this.idHistoria);
          this.id_fragmingam = response.data.id;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async cargarTaSistolica() {
      const response = await examenFisicoService.showByHc(this.idHistoria);
      this.ta_sistolica = response.data.pa_sistolica;
    },
  },
  async created() {
    this.genero = this.sexo;
    if (this.finalizada === "N") {
      const response = await fragmingamService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.edad_u = response.data.edad;
        this.col_total = response.data.col_total;
        this.col_hdl = response.data.col_hdl;
        this.presencia_diabetes = response.data.presencia_diabetes;
        this.tratamiento_hipertension = response.data.tratamiento_hipertension;
        this.ta_sistolica = response.data.ta_sistolica;
        this.fuma_cigarrillos = response.data.fuma_cigarrillos;
        this.porcentaje = response.data.porcentaje;
        this.riesgo = response.data.riesgo;
        this.id_fragmingam = response.data.id;
      }
    }
    this.cargarTaSistolica();
  },
  computed: {
    edad_u: function () {
      if (isEmpty(this.fecha_nacimiento)) return 0;
      return dayjs().diff(this.fecha_nacimiento, "year");
    },
  },
};
</script>
